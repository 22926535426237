import store from "@/store";

export default function actionIsAllowed(statutAffiche, tmpButton, me) {
  const hasPerms = store.getters.hasPerms;
  const isHorsMarche = store.getters.isHorsMarche;
  const user = store.getters.user;

  let button = tmpButton;
  if (!["creer_devis", "valider_etablissement", "soumettre_region", "approuver_region", "commander"].includes(button)) {
    // Si c'est un bouton du menu contextuel
    button = `${me ? "me" : "other"}_${button}`;
  }

  const isOp = user.role === "Opérateur LDE";

  const regles = {
    attente_selection: { // Liste créée
      // Boutons StatusApprobation
      creer_devis: () => hasPerms(["can_liste_to_devis"]),
      valider_etablissement: () => false,
      soumettre_region: () => false,
      approuver_region: () => false,
      commander: () => isHorsMarche && hasPerms(["can_liste_to_commande"]),
      // Menu contextuel : mes devis
      me_to_liste: () => false,
      me_dmd_modif: () => false, // On ne peut pas se demander une modif à soi-même
      me_modif: () => false,
      me_suppr: () => false,
      me_rejet: () => false,
      // Menu contextuel : devis de qqun d'autre
      other_to_liste: () => false,
      other_dmd_modif: () => false,
      other_modif: () => false,
      other_suppr: () => false,
      other_rejet: () => false,
    },
    attente_validation: { // Devis créé
      // Boutons StatusApprobation
      creer_devis: () => false,
      valider_etablissement: () => !isHorsMarche && hasPerms(["can_transmettre_devis", "change_devis"]),
      soumettre_region: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      approuver_region: () => false,
      commander: () => isHorsMarche && hasPerms(["can_liste_to_commande"]),
      // Menu contextuel : mes devis
      me_to_liste: () => true,
      me_dmd_modif: () => false, // On ne peut pas se demander une modif à soi-même
      me_modif: () => true,
      me_suppr: () => true,
      me_rejet: () => !isHorsMarche && isOp,
      // Menu contextuel : devis de qq d'autre
      other_to_liste: () => hasPerms(["can_transmettre_devis"], ["can_liste_to_commande"]),
      other_dmd_modif: () => hasPerms(["can_transmettre_devis"], ["can_liste_to_commande"]),
      other_modif: () => hasPerms(["can_transmettre_devis"], ["can_liste_to_commande"]),
      other_suppr: () => hasPerms(["can_transmettre_devis"], ["can_liste_to_commande"]),
      other_rejet: () => !isHorsMarche && isOp,
    },
    attente_soumission: { // Validé Établissement
      // Boutons StatusApprobation
      creer_devis: () => false,
      valider_etablissement: () => false,
      soumettre_region: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      approuver_region: () => false,
      commander: () => (isHorsMarche && hasPerms(["can_liste_to_commande"])) || hasPerms(["can_transmettre_devis"]),
      // Menu contextuel : mes devis
      me_to_liste: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      me_dmd_modif: () => false, // On ne peut pas se demander une modif à sois-même
      me_modif: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      me_suppr: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      me_rejet: () => !isHorsMarche && isOp,
      // Menu contextuel : devis de qq d'autre
      other_to_liste: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_dmd_modif: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_modif: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_suppr: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_rejet: () => !isHorsMarche && isOp,
    },
    attente_approbation: { // Soumis Région
      // Boutons StatusApprobation
      creer_devis: () => false,
      valider_etablissement: () => false,
      soumettre_region: () => false,
      approuver_region: () => !isHorsMarche && hasPerms(["can_devis_to_cmd"]),
      commander: () => !isHorsMarche && hasPerms(["can_devis_to_cmd"]),
      // Menu contextuel : mes devis
      me_to_liste: () => !isHorsMarche && isOp,
      me_dmd_modif: () => false, // On ne peut pas se demander une modif à sois-même
      me_modif: () => !isHorsMarche && isOp,
      me_suppr: () => !isHorsMarche && isOp,
      me_rejet: () => !isHorsMarche && hasPerms(["can_devis_to_cmd"]),
      // Menu contextuel : devis de qq d'autre
      other_to_liste: () => !isHorsMarche && isOp,
      other_dmd_modif: () => !isHorsMarche && isOp,
      other_modif: () => !isHorsMarche && isOp,
      other_suppr: () => !isHorsMarche && isOp,
      other_rejet: () => !isHorsMarche && hasPerms(["can_devis_to_cmd"]),
    },
    attente_commande: { // Validé Région ou Validé ETS en HM
      // Boutons StatusApprobation
      creer_devis: () => false,
      valider_etablissement: () => false,
      soumettre_region: () => false,
      approuver_region: () => false,
      commander: () => (isHorsMarche && hasPerms(["can_liste_to_commande"])) || hasPerms(["can_devis_to_cmd"]),
      // Menu contextuel : mes devis
      me_to_liste: () => (!isHorsMarche && isOp) || (isHorsMarche && hasPerms(["can_devis_to_cmd"])),
      me_dmd_modif: () => false, // On ne peut pas se demander une modif à sois-même
      me_modif: () => (!isHorsMarche && isOp) || (isHorsMarche && hasPerms(["can_devis_to_cmd"])),
      me_suppr: () => (!isHorsMarche && isOp) || (isHorsMarche && hasPerms(["can_devis_to_cmd"])),
      me_rejet: () => !isHorsMarche && hasPerms(["can_devis_to_cmd"]),
      // Menu contextuel : devis de qq d'autre
      other_to_liste: () => (!isHorsMarche && isOp) || (isHorsMarche && hasPerms(["can_devis_to_cmd"])),
      other_dmd_modif: () => (!isHorsMarche && isOp) || (isHorsMarche && hasPerms(["can_devis_to_cmd"])),
      other_modif: () => (!isHorsMarche && isOp) || (isHorsMarche && hasPerms(["can_devis_to_cmd"])),
      other_suppr: () => (!isHorsMarche && isOp) || (isHorsMarche && hasPerms(["can_devis_to_cmd"])),
      other_rejet: () => !isHorsMarche && hasPerms(["can_devis_to_cmd"]),
    },
    attente_modification: {
      // Boutons StatusApprobation
      creer_devis: () => false,
      valider_etablissement: () => false,
      soumettre_region: () => false,
      approuver_region: () => false,
      commander: () => false,
      // Menu contextuel : mes devis
      me_to_liste: () => true,
      me_dmd_modif: () => false, // On ne peut pas se demander une modif à sois-même
      me_modif: () => false, // on ne peut pas modifier un devis qui est déjà en modification
      me_suppr: () => true,
      me_rejet: () => !isHorsMarche && isOp,
      // Menu contextuel : devis de qqun d'autre
      other_to_liste: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_dmd_modif: () => false, // on ne peut pas modifier un devis qui est déjà en modification
      // on ne peut pas modifier un devis qui est déjà en modification
      other_modif: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_suppr: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_rejet: () => !isHorsMarche && isOp,
    },
    refus: { // Devis rejeté
      // Boutons StatusApprobation
      creer_devis: () => false,
      valider_etablissement: () => false,
      soumettre_region: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      approuver_region: () => false,
      commander: () => false,
      // Menu contextuel : mes devis
      me_to_liste: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      me_dmd_modif: () => false, // On ne peut pas se demander une modif à sois-même
      me_modif: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      me_suppr: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      me_rejet: () => false, // On ne peut pas rejeter un devis déjà rejeté
      // Menu contextuel : devis de qqun d'autre
      other_to_liste: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      other_dmd_modif: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      other_modif: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      other_suppr: () => !isHorsMarche && hasPerms(["can_transmettre_devis"]),
      other_rejet: () => false, // On ne peut pas rejeter un devis déjà rejeté
    },
    // TODO
    expire: { // Devis rejeté
      // Boutons StatusApprobation
      creer_devis: () => false,
      valider_etablissement: () => false,
      soumettre_region: () => false,
      approuver_region: () => false,
      commander: () => false,
      // Menu contextuel : mes devis
      me_to_liste: () => false,
      me_dmd_modif: () => false,
      me_modif: () => false,
      me_suppr: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      me_rejet: () => false,
      // Menu contextuel : devis de qqun d'autre
      other_to_liste: () => false,
      other_dmd_modif: () => false,
      other_modif: () => false,
      other_suppr: () => hasPerms(["can_transmettre_devis"], ["can_devis_to_cmd"]),
      other_rejet: () => false,
    },
  };

  // Garder ce console.log au cas où...
  // console.log(statutAffiche, button);
  return regles[statutAffiche][button]();
}
