<template>
  <div class="context-menu">
    <ButtonPopover
      ref="popover"
      v-model="isPopoverOpen"
      color="primary"
      variant="ghost"
      icon="left"
      placement="bottom-right"
      trigger="click"
      :disabled="!blocActionsSelections.length
        && (!blocActionsGlobales.length || !blocActionsGlobales[0].actions.length)"
    >
      <template #left-icon>
        <UilEllipsisV
          class="context-menu-icon"
          size="16"
        />
      </template>

      <!-- @slot Emplacement pour des éléments en haut du menu. -->
      <slot name="header" />

      <div class="actions-content">
        <div
          v-if="selections.length"
          class="selection-counter"
        >
          <p class="label-counter flex-vcenter text-regular">
            {{ $t("filtre.selection") }}
          </p>
          <Counter :value="selections.length" />
        </div>
        <p
          v-if="blocActionsSelections.length"
          class="actions-title text-small italic"
        >
          {{ $t("context-menu.actions-applicables-a-la-selection") }}
          <template v-if="!selections.length">
            ({{ $tc("general.selectionnez-au-moins-x-elements", 1, { nombre: 'un', }) }})
          </template>
        </p>
        <ul
          v-if="blocActionsSelections.length"
          class="bloc text-medium"
        >
          <li
            v-for="action in blocActionsSelections"
            :key="action.slug"
            class="options-list-item"
          >
            <button
              type="button"
              class="options-list-item-btn has-icon"
              :disabled="!selections.length || action.disabled"
              @click="selections.length ? handleAction(action) : null"
            >
              <component
                :is="action.icon"
                class="icon"
              />
              {{ action.label }}
            </button>
          </li>
        </ul>

        <ul
          v-if="blocActionsGlobales.some((bloc) => bloc.actions.length)"
          class="bloc"
        >
          <li
            v-for="bloc in blocActionsGlobales"
            :key="bloc.title"
          >
            <p
              v-if="bloc.title"
              class="actions-title text-small italic"
            >
              {{ bloc.title }}
            </p>
            <ul class="text-medium">
              <li
                v-for="action in bloc.actions"
                :key="action.slug"
                class="options-list-item"
              >
                <button
                  type="button"
                  class="options-list-item-btn has-icon"
                  :disabled="action.disabled"
                  @click="!action.disabled ? handleAction(action) : ''"
                >
                  <component
                    :is="action.icon"
                    size="16"
                    class="icon"
                  />
                  {{ action.label }}
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </ButtonPopover>
  </div>
</template>

<script>
import { ButtonPopover, Counter } from "@lde/core_lde_vue";

import { UilEllipsisV } from "@iconscout/vue-unicons";

/**
 * Affiche un bouton lié à un menu contextuel.
 */
export default {
  name: "ContextMenu",
  components: {
    ButtonPopover,
    Counter,
    UilEllipsisV,
  },
  props: {
    /**
     * Objets sélectionnés.
     */
    selections: {
      type: Array,
      default: () => [],
    },
    /**
     * Actions applicables à la sélection.
     * On a besoin d'un tableau d'objets car il est possible d'avoir plusieurs blocs d'actions globales, dont le titre
     * permet de les différencier.
     */
    blocActionsSelections: {
      type: Array,
      default: () => [],
    },
    /**
     * Actions applicables à tout.
     */
    blocActionsGlobales: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    /**
     * Déclenché au clic d'un bouton.
     * @param {Number} index du bouton cliqué.
     */
    "click-action",
  ],
  data() {
    return {
      isPopoverOpen: false,
    };
  },
  methods: {
    /**
     * Gestion des actions au clic.
     * @param {String} action Slug de l'action à effectuer.
     */
    handleAction(action) {
      // Lorsqu'on clique sur une action, on ferme le menu
      this.$refs.popover.togglePopover();
      this.$emit("click-action", action);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/context_menu.scss";
</style>
